import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiScenariosManagement from '../../../api/scenarios-management';
import ScenarioTable from "../scenario-management/components/scenario-table";
import ScenarioPage from "../scenario-management/components/scenario-page";
import useNotification from "../../../components/notification/notification";
import { Body } from "../../../components/main-body/body";
import "./styles.css";
import ApiOnboardingManagement from "../../../api/onboarding-management";

const normalizeScenarios = (scenarios) => {
  return scenarios.map((scenario) => ({
    ...scenario,
    name: scenario.title,
    ScenarioVersion: scenario.OnboardingSurveyVersion,
  }));
};

const OnboardingManagement = () => {
  const [scenarios, setScenarios] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState({});
  const [cohorts, setCohorts] = useState([]);
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState();

  const getScenarios = async (deselect) => {
    setLoading(true);
    return ApiOnboardingManagement.getScenarios()
      .then((r) => {
        const { onboardingSurveys, groupOnboardingSurveys } = r.data;
        setCohorts(
          groupOnboardingSurveys.map((cohort) => ({
            id: cohort.id,
            label: cohort.EnrollmentFieldOption.value,
          }))
        );

        const scenarios = normalizeScenarios(onboardingSurveys);
        setScenarios(scenarios);

        if (selectedScenario.id && !deselect) {
          setSelectedScenario({});
          setSelectedScenario(
            scenarios.find((s) => s.id === selectedScenario.id)
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDailyTasks = async () => {
    return ApiScenariosManagement.getDailyTasks().then((r) => {
      setTasks(r.data.dailyTasks);
    });
  };

  const handleDuplicate = (scenario) => {
    setLoading(true);
    ApiOnboardingManagement.duplicateScenario(scenario.id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.duplicated", {
                  scenario: scenario.title,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getScenarios();
    getDailyTasks();
  }, []);

  const createScenario = () => {
    setLoading(true);
    ApiOnboardingManagement.createScenario()
      .then((r) => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.scenario.notification.created", {
                scenario: r.data.onboardingSurvey.title,
              })}
            </p>
          ),
        });

        setSelectedScenario(normalizeScenarios([r.data.onboardingSurvey])[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteScenario = (id, value) => {
    setLoading(true);
    ApiOnboardingManagement.deleteScenario(id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.deleted", {
                  scenario: value,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectScenario = (scenario) => {
    setSelectedScenario(scenario);
    setOpen(true);
  };

  const handleDeselectScenario = async () => {
    setSelectedScenario({});
    await getScenarios(true);
  };

  const headerProps = useMemo(
    () => ({
      title: "Onboarding Management",
      create: {
        label: t("administration.onboarding.create"),
        onClick: createScenario,
      },
    }),
    [isOpen, selectedScenario]
  );

  return (
    <Body header={headerProps}>
      {selectedScenario?.id ? (
        <ScenarioPage
          scenario={selectedScenario}
          toList={handleDeselectScenario}
          refreshScenario={getScenarios}
          openNotification={openNotification}
          api={ApiOnboardingManagement}
          cohorts={cohorts}
          tasks={tasks}
          isOnboarding
        />
      ) : (
        <ScenarioTable
          data={scenarios}
          onHandleRowClick={handleSelectScenario}
          deleteScenario={deleteScenario}
          isOnboarding
          handleDuplicate={handleDuplicate}
          loading={loading}
        />
      )}
    </Body>
  );
};

export default OnboardingManagement;
