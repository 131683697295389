import classNames from "classnames";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Skeleton} from 'antd';
import "react-lazy-load-image-component/src/effects/blur.css";

const ImgSkeleton = ({ width, proportion, active, withIcon=false }) => {
  return (
    <div>
      {withIcon ? (
        <Skeleton.Image style={{ width, height: width * (proportion / 100) }} active={active}/>
      ) : (
        <Skeleton.Button style={{ width, height: width * (proportion / 100) }} active={active}/>
      )}
    </div>
  );
}

export const TableCellPicture = ({pageId, shouldDropCache, width = 150, proportion = 100}) => {
  const [loadingError, setLoadingError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onError = (e) => {
    setLoadingError(e);
    setIsLoading(false);
  }

  const onLoad = () => {
    setIsLoading(false);
    setLoadingError(null);
  }

  if (loadingError) {
    return (
      <div className={classNames("lf-table-cell-picture", {})}>
        <div className={classNames("lf-table-cell-picture__inside")}>
          <ImgSkeleton width={width} proportion={proportion} withIcon />
        </div>
      </div>
    );
  }

  return (
    <div style={{ width }} className={classNames("lf-table-cell-picture", {})}>
      <div className={classNames("lf-table-cell-picture__inside")}>
        <LazyLoadImage
          className={classNames("lf-table-cell-picture__img")}
          key={shouldDropCache ? Date.now() : pageId}
          src={`${
            process.env.REACT_APP_DEV_URL || document.location.origin
          }/api/v1/pages/${pageId}/icon-mini?${shouldDropCache ? `t=${Date.now()}` : ""}`}
          loading="lazy"
          style={{
            width: width,
            minWidth: width,
            height: width * (proportion / 100),
          }}
          onError={onError}
          onLoad={onLoad}
        />
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              zIndex: 10,
              top: 0,
              width: width,
              height: width * (proportion / 100),
              background: 'white',
            }}
          >
            <ImgSkeleton
              width={width}
              proportion={proportion}
              active
            />
          </div>
        )}
      </div>
    </div>
  );
};
