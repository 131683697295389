import { useEffect, useState, useRef } from "react";

const useScenarioSimulatorController = (
  scenario,
  embeddedScenarios,
  isReady
) => {
  const currentScenarioRef = useRef(scenario);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [userAnswers, setUserAnswers] = useState([]);
  const [history, setHistory] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [normalizedEmbeddedScenarios, setNormalizedEmbeddedScenarios] = useState({});

  const setCurrentQuestionAfterNormalizeQuestionText = (question) => {
    const joinedMessages = question.data?.message_alternatives?.length
      ? [question.data.message, ...question.data.message_alternatives]
      : null;

    const randomIndex = Math.floor(Math.random() * joinedMessages?.length);

    const normalizeMessage = {
      message: joinedMessages?.[randomIndex] || question.data?.message,
    };

    const normalizeQuestion = {
      ...question,
      data: { ...question.data, ...normalizeMessage },
    };

    setCurrentQuestion(normalizeQuestion);
  };

  function addNextNodesToFinishNode(obj, nextNode) {
    if (!obj || !nextNode.nextNodes) return {};
    const startNode = Object.values(obj).find(
      (node) => node.type === "StartNode"
    );
    const finishNode = Object.values(obj).find(
      (node) => node.type === "FinishNode"
    );
    const nodeAfterStartNode = Object.values(obj).find(
      (node) => node.prevNodes?.includes(startNode.id)
    );
    const nodeBeforeFinishNode = Object.values(obj).find(
      (node) => node.nextNodes?.includes(finishNode.id)
    );

    if (nodeBeforeFinishNode) {
      nodeBeforeFinishNode.nextNodes = [...nextNode.nextNodes];
    }
    if (nodeAfterStartNode) {
      nextNode = nodeAfterStartNode;
    }

    const filteredObj = Object.values(obj).filter(
      (node) => node.id !== finishNode.id && node.id !== startNode.id
    );

    return [filteredObj, nextNode];
  }

  const getFirstQuestionOfScenario = (scenario) => {
    const startNode = Object.values(scenario).find(
      (node) => node.type === "StartNode"
    );
    const firstQuestion = scenario[startNode.nextNodes[0]];
    setCurrentQuestionAfterNormalizeQuestionText(firstQuestion);
  };

  const toNextQuestion = (nextQuestionId, historyOverride) => {
    setInProcess(true);

    setTimeout(() => {
      setHistory([...historyOverride ?? history, currentQuestion]);
      setCurrentQuestionAfterNormalizeQuestionText(
      currentScenarioRef.current[nextQuestionId]
      );
      setInProcess(false);
    }, Math.floor(Math.random() * (3000 - 1500) + 1500));
  };

  const rollBack = (id, answer) => {
    setInProcess(false);

    const selectedHistoryIndex = history.findIndex((i) => i.id === id);
    const newHistory = [...history].slice(0, selectedHistoryIndex);
    const newUserAnswers = userAnswers.slice(0, selectedHistoryIndex);

    setHistory(newHistory);
    setUserAnswers(newUserAnswers, { id, ...answer });
    setCurrentQuestionAfterNormalizeQuestionText(currentScenarioRef.current[id]);
  };

  const rollbackLocal = (id, answer) => {
    const selectedHistoryIndex = history.findIndex((i) => i.id === id);
    const newHistory = [...history].slice(0, selectedHistoryIndex + 1);
    const newUserAnswers = [...userAnswers.slice(0, selectedHistoryIndex), { id, ...answer }];

    return {
      newHistory,
      newUserAnswers,
    }
  }

  const jumpIntoEmbeddedScenario = ({ embeddedScenario, nextQuestionId, answersToSet, newHistoryToSet, answer, id }) => {
    const nextNode = currentScenarioRef.current[nextQuestionId] ;
    const [nextNodesToFinishNode, nextNodeUpdated] = addNextNodesToFinishNode(embeddedScenario ?? {}, nextNode);
    const updatedScenario =  {
      ...currentScenarioRef.current,
      ...nextNodesToFinishNode.reduce((acc, node) => {
        acc[node.id] = node;
        return acc;
      }, {}),
    };
    currentScenarioRef.current[nextQuestionId] = nextNodeUpdated;
    currentScenarioRef.current = updatedScenario;

    setUserAnswers([...answersToSet, { id, ...answer }]);
    toNextQuestion(nextNodesToFinishNode[0].id, newHistoryToSet)
  }

  const setAnswer = (id, answer) => {
    if (inProcess) return;

    const nextQuestionId = currentScenarioRef.current[id].nextNodes[0];
    const nextNode = currentScenarioRef.current[nextQuestionId];

    if (!nextNode) return;

    const isPreviosAnswer = userAnswers.find((answer) => answer.id === id);

    const { newHistory, newUserAnswers } = rollbackLocal(id, answer);
    const answersToSet = isPreviosAnswer ? newUserAnswers : userAnswers;
    const newHistoryToSet = isPreviosAnswer ? newHistory : history;

    if (nextNode.data?.nodeType === "scenario:picker") {
      const embeddedScenario = normalizedEmbeddedScenarios[nextNode.id];

      if (embeddedScenario) {
        jumpIntoEmbeddedScenario({
          embeddedScenario,
          nextQuestionId,
          answersToSet,
          newHistoryToSet,
          answer,
          id,
        });
        return;
      }
    }

    if (nextNode.type === "GenericNode" && !isPreviosAnswer) {
      setUserAnswers([...answersToSet, { id, ...answer }]);
      toNextQuestion(nextQuestionId, newHistoryToSet);

      return;
    }

    if (nextNode.type === "IfConditionNode") {
      if (answer) {
        setUserAnswers([...answersToSet, {id, ...answer}]);
      }
      const normalizedConditionals = nextNode.data.condStatements.map(
        (state) => state.value?.map((v) => v.value) ?? null
      );

      let nextNodeIndex = normalizedConditionals.findIndex((v) => v?.includes(answer?.value)) ?? -1;
      if (nextNodeIndex === -1) {
        nextNodeIndex = normalizedConditionals.findIndex((v) => v?.includes(userAnswers.find((a) => a.id === nextNode.data.condSourceId)?.id)) ?? -1;
      }
      if (nextNodeIndex === -1) {
        nextNodeIndex = normalizedConditionals.findIndex((v) => v?.includes(userAnswers.find((a) => a.id === nextNode.data.condSourceId)?.value)) ?? -1;
      }
      if (nextNodeIndex === -1) {
        nextNodeIndex = normalizedConditionals.findIndex((v) => v?.filter((value) =>
          userAnswers
            .find((a) => a.id === nextNode.data.condSourceId)
            ?.answers?.map((a) => a.value)
            .includes(value)
        ).length) ?? -1;
      }

      const jumpToAnotherBranch =
        currentScenarioRef.current[nextNode.nextNodes[nextNodeIndex]];

      const resolvedNextNode = currentScenarioRef.current[jumpToAnotherBranch.nextNodes[0]];
      if (resolvedNextNode.data.nodeType === 'scenario:picker') {
        const embeddedScenario = normalizedEmbeddedScenarios[jumpToAnotherBranch.nextNodes[0]];

        if (embeddedScenario) {
          jumpIntoEmbeddedScenario({
            embeddedScenario,
            nextQuestionId: resolvedNextNode.nextNodes[0],
            answersToSet,
            newHistoryToSet,
            answer,
            id,
          });
          return;
        }
      } else {
        setUserAnswers([...answersToSet, { id, ...answer }]);
        toNextQuestion(jumpToAnotherBranch.nextNodes[0], newHistoryToSet);
      }
      return;
    }

    if (nextNode.type === "IfCloseNode") {
      if (isPreviosAnswer) {
        rollBack(id, answer);
        return;
      }
      setUserAnswers([...answersToSet, { id, ...answer }]);
      toNextQuestion(nextNode.nextNodes[0], newHistoryToSet);
      return;
    }

    toNextQuestion(nextQuestionId, newHistoryToSet);
    setUserAnswers([...answersToSet, { id, ...answer }]);

  };

  useEffect(() => {
    if (
      currentQuestion.id &&
      !inProcess &&
      ![
        "message:single",
        "message:multi",
        "message:input",
        "message:picker",
        "message:date",
      ].includes(currentQuestion?.data?.nodeType)
    ) {
      setAnswer(currentQuestion.id);
    }
  }, [currentQuestion, inProcess]);

  useEffect(() => {
    const normalizedItems = {};
    const getNormalizedItemsForScenario = (scenario, id) => {
      if (scenario && scenario.data) {
        normalizedItems[id] = JSON.parse(scenario.data);
      }
      if(scenario && scenario.embeddedScenarios && Object.keys(scenario.embeddedScenarios).length) {
        Object.keys(scenario.embeddedScenarios).forEach((key) => {
          return getNormalizedItemsForScenario(scenario.embeddedScenarios[key], key);
        });
      }
    }
    Object.keys(embeddedScenarios).forEach((key) => {
      getNormalizedItemsForScenario(embeddedScenarios[key], key);
    });
    setNormalizedEmbeddedScenarios(normalizedItems);
  }, [embeddedScenarios]);

  useEffect(() => {
    if (isReady && !currentQuestion.id && !history.length) {
      getFirstQuestionOfScenario(currentScenarioRef.current);
    }
  }, [isReady]);

  return {
    currentQuestion,
    userAnswers,
    history,
    setAnswer,
    toNextQuestion,
    inProcess,
  };
};

export default useScenarioSimulatorController;
