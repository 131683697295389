import { Button, Divider, Input, Select, Skeleton, Switch } from "antd";
import "./form.css";
import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import UploadAvatar from "../upload-avatar/upload-avatar";
import UserActivityStatus from "../user-activity-status/user-activity-status";
import { Icon } from "../icon/icon";
import Dragger from "antd/es/upload/Dragger";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImgSkeletonStyle = {
  zoom: 3,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: 'auto',
  height: 'auto',
};

const ImgSkeleton = ({ active, withIcon = false }) => {
  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {withIcon ? (
        <Skeleton.Image
          style={ImgSkeletonStyle}
          active={active}
        />
      ) : (
        <Skeleton.Button
          style={ImgSkeletonStyle}
          active={active}
        />
      )}
    </div>
  )
}

const { Option } = Select;

export const FormItem = ({ label, children }) => {
  return (
    <div className={"ant-form-item"}>
      <div className={"ant-row ant-form-item-row"}>
        <div className={"ant-col ant-form-item-label"}>
          <label>{label}</label>
        </div>
        <div className={"ant-col ant-form-item-control"}>
          <div className={"ant-form-item-control-input"}>
            <div className={"ant-form-item-control-input-content"}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormInput = forwardRef(
  (
    {
      name,
      defaultValue,
      maxLength,
      readonly,
      editMode,
      disabled,
      type = "text",
      className,
      bordered = true,
      onChange = () => {},
      ...rest
    },
    ref
  ) => {
    return (
      <Input
        className={classNames(className, {
          "lf-form-element": true,
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        bordered={bordered}
        type={type}
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        onChange={onChange}
        data-1p-ignore
        {...rest}
        ref={ref}
      />
    );
  }
);

export const FormTextarea = forwardRef(
  (
    { name, defaultValue, maxLength, readonly, editMode, disabled, ...rest },
    ref
  ) => {
    return (
      <Input.TextArea
        className={classNames("lf-form-element", {
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        type="text"
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        data-1p-ignore
        autoSize={{ minRows: 1, maxRows: 1000 }}
        {...rest}
        ref={ref}
      />
    );
  }
);
export const FormHeaderTextarea = forwardRef(
  (
    {
      name,
      defaultValue,
      maxLength,
      readonly,
      editMode,
      disabled,
      placeholder,
      ...rest
    },
    ref
  ) => {
    return (
      <Input.TextArea
        className={classNames("lf-form-element", "lf-form-element-header", {
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        type="text"
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        placeholder={placeholder}
        data-1p-ignore
        autoSize={{ minRows: 1, maxRows: 1000 }}
        {...rest}
        ref={ref}
      />
    );
  }
);

export const FormInputButton = forwardRef(
  ({ value, onClick, readonly, ...rest }, ref) => {
    return (
      <Button
        className={classNames(
          "lf-form-element",
          "lf-form-element--type-input-button"
        )}
        type="text"
        onClick={onClick}
        style={{ pointerEvents: readonly ? "none" : undefined }}
        {...rest}
        ref={ref}
      >
        {value}
      </Button>
    );
  }
);

export const FormAvatar = ({ value, onChange, readonly, ...rest }) => {
  if (readonly) {
    return (
      <div
        className="lf-form-avatar"
        style={{ backgroundImage: value ? `url(${value})` : undefined }}
      >
        {!value && <Icon name={"user"} size={18} color={"text-2"} />}
      </div>
    );
  }
  return (
    <div className="lf-form-avatar">
      <UploadAvatar onChange={onChange} value={value} />
    </div>
  );
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const FormImage = ({
  value,
  onChange,
  readonly,
  size,
  articleId,
  updateImageStatus,
  updateIsUpdatedImage,
  ...rest
}) => {
  const imageInstanse = new Image();
  const [imageUrl, setImageUrl] = useState();
  const [hasImage, setHasImage] = useState(null);
  const [removed, setRemoved] = useState(false);

  const updateFormImageValue = (value) => {
    if (updateImageStatus) {
      updateImageStatus(value);
    }
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
      onChange(url);
      updateFormImageValue(true);
      updateIsUpdatedImage(true);
      if (removed) {
        setRemoved(false);
      }
    });
  };

  const removeImageUrl = () => {
    setImageUrl(null);
  };

  const changeImageStatus = (status) => {
    if (status) {
      removeImageUrl();
    }
    setHasImage(status);
    updateFormImageValue(status);
  };

  useEffect(() => {
    if (value) {
      imageInstanse.src = value;
      setImageUrl(imageInstanse.src);
    } else {
      removeImageUrl();
    }
  }, [value]);

  return (
    <div
      className={classNames("lf-form-image", {
        "lf-form-image--has-image": !!imageUrl || hasImage,
        "lf-form-image--readonly": readonly,
      })}
    >
      <Dragger
        onChange={handleChange}
        showUploadList={false}
        disabled={readonly}
      >
        {!readonly && (
          <div className={"lf-form-image__button-group"}>
            <Button
              className={"lf-form-image__button-upload"}
              type={"primary"}
              style={{ marginRight: 8 }}
            >
              Upload
            </Button>
            {imageUrl && (
              <Button
                className={"lf-form-image__button-remove"}
                onClick={(e) => {
                  e.stopPropagation();
                  setImageUrl(true);
                  onChange("");
                  setHasImage(false);
                  updateFormImageValue(false);
                  updateIsUpdatedImage(true);
                  setRemoved(true);
                }}
              >
                Remove
              </Button>
            )}
          </div>
        )}

        <div
          className={"lf-form-image__inside"}
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
            paddingBottom: imageUrl || removed ? "60%" : null,
          }}
        >
          {hasImage !== false && !imageUrl && !removed ? (
            <LazyLoadImage
              src={`${
                process.env.REACT_APP_DEV_URL || document.location.origin
              }/api/v1/pages/${articleId}/icon`}
              effect="blur"
              loading="lazy"
              onError={() => {
                changeImageStatus(false);
                updateIsUpdatedImage(true);
              }}
              onLoad={() => changeImageStatus(true)}
              height={369}
              width={"100%"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : null}
          {
            hasImage === null && (
              <div className="lf-form-image__skeleton">
                <ImgSkeleton width={'100%'} proportion={60} active />
              </div>
            )
          }
          {
            hasImage === false && !imageUrl && readonly && (
              <div className="lf-form-image__skeleton">
                <ImgSkeleton width={'100%'} proportion={60} withIcon />
              </div>
            )
          }
        </div>
      </Dragger>
    </div>
  );
};

export const FormHeader = ({ label }) => {
  return <div className="lf-form-header">{label}</div>;
};

export const FormSwitch = ({ checked, onChange, readOnly }) => {
  return (
    <div className="lf-form-switch">
      <Switch onChange={onChange} checked={checked} disabled={readOnly} />
    </div>
  );
};
export const FormUserStatus = ({ status }) => {
  return (
    <div className="lf-form-user-status">
      <UserActivityStatus userStatus={status} />
    </div>
  );
};

export const FormSelect = ({
  onSelect = () => {},
  defaultValue,
  disabled,
  options,
  readonly,
  ...props
}) => {
  return (
    <div
      className={classNames("lf-form-select", {
        "lf-form-select--readonly": readonly || disabled,
      })}
    >
      <Select
        options={options}
        disabled={disabled}
        defaultValue={defaultValue}
        onSelect={onSelect}
        bordered={false}
        suffixIcon={
          <Icon
            name={"arrow-down"}
            color={"text-2"}
            size={12}
            hidden={readonly || disabled}
          />
        }
        {...(readonly ? { onSelect: undefined } : {})}
        {...props}
      />
      {readonly && <div className={"lf-form-select__readonly"} />}
    </div>
  );
};

export const FormSelectWithSearch = ({
  onSelect = () => {},
  defaultValue,
  disabled,
  options = [],
  canAddNew,
  isURL,
  readonly,
  multiline,
  addBtnText = "Add Source URL",
  ...props
}) => {
  const [selectOptions, setSelectOptions] = useState(options);
  const [searchValue, setSearchValue] = useState();
  const [value, setValue] = useState(defaultValue);
  const [showUrlError, setShowUrlError] = useState();

  useEffect(() => {
    if (showUrlError) {
      setShowUrlError(false);
    }
  }, [searchValue]);

  const handleSelect = (v) => {
    setValue(v);
    onSelect(v);
  };

  const addNewOption = () => {
    if (!searchValue) return;
    if (isURL && !searchValue.includes("http")) {
      setShowUrlError(true);
      return;
    }

    const newOption = { value: searchValue.trim(), id: null, subLabel: null };
    setSelectOptions([...selectOptions, newOption]);
    setSearchValue("");
    setValue(newOption);
    onSelect(newOption);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const renderDropdown = () => (
    <div>
      <div>
        <Input
          value={searchValue}
          bordered={false}
          placeholder="Find or add new link"
          onChange={handleSearch}
          maxLength={255}
          prefix={
            <Icon
              name="search"
              color="text-2"
              size={12}
              hidden={readonly || disabled}
            />
          }
        />
        <Divider style={{ margin: 0 }} />
      </div>
      <div className="lf-form-select-option-box">
        {selectOptions.filter((option) =>
          searchValue ? option.value.includes(searchValue) : true
        ).length ? (
          selectOptions
            .filter((option) =>
              searchValue ? option.value.includes(searchValue) : true
            )
            .map((option) => (
              <div
                key={option.value}
                className={classNames(
                  "lf-form-select-search-box lf-form-select-option",
                  {
                    "lf-form-select-option--selected":
                      value?.value === option?.value,
                    "lf-form-select-option--flex": option.subLabel,
                  }
                )}
                onClick={() => handleSelect(option)}
              >
                {option.subLabel && (
                  <span className="lf-form-select-option-label">
                    {option.subLabel}
                  </span>
                )}
                <span
                  className={
                    option.subLabel
                      ? "lf-form-select-option-sub-label"
                      : "lf-form-select-option-label"
                  }
                >
                  {option.value}
                </span>
              </div>
            ))
        ) : (
          <div className="lf-form-select-search-box" onClick={addNewOption}>
            {showUrlError ? (
              <span className="lf-form-select-search-url--error">
                Please enter a valid URL
              </span>
            ) : (
              <>
                <Icon name="add" size={12} hidden={readonly || disabled} />
                <span>{addBtnText}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classNames("lf-form-select", {
        "lf-form-select--readonly": readonly || disabled,
      })}
    >
      <Select
        value={value}
        disabled={disabled}
        onSelect={handleSelect}
        bordered={false}
        className={multiline ? "lf-select-multiline" : ""}
        dropdownRender={renderDropdown}
        destroyOnClose
        suffixIcon={
          <Icon
            name="arrow-down"
            color="text-2"
            size={12}
            hidden={readonly || disabled}
          />
        }
        {...(readonly ? { onSelect: undefined } : {})}
        {...props}
      >
        {selectOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            <div style={{ padding: "10px 0px" }}>
              {option.subLabel && (
                <div className="lf-form-select-option-label">
                  {option.subLabel}
                </div>
              )}
              <div
                className={
                  option.subLabel
                    ? "lf-form-select-option-sub-label"
                    : "lf-form-select-option-label"
                }
              >
                {option.value}
              </div>
            </div>
          </Option>
        ))}
      </Select>
      {readonly && <div className="lf-form-select__readonly" />}
    </div>
  );
};
//   onSelect = () => {},
//   defaultValue,
//   disabled,
//   options = [],
//   canAddNew,
//   readonly,
//   multiline,
//   ...props
// }) => {
//   const [selectOptions, setSelectOptions] = useState(options);
//   const [searchValue, setSearchValue] = useState(defaultValue);
//   const [inputValue, setInputValue] = useState();
//   const [value, setValue] = useState();

//   const onHandleSelect = (v) => {
//     setValue(v);
//     onSelect(v);
//   };

//   const addNewOption = (searchValue, selectOptions) => {
//     console.log(searchValue);
//     setSelectOptions([...selectOptions, { value: searchValue, id: null }]);
//     setSearchValue(null);
//     setValue({ value: searchValue, id: null });
//   };

//   const handleSearch = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const dropdownRender = (addNewOption, handleSearch, value) => {
//     console.log(selectOptions);
//     return (
//       <div>
//         <div>
//           <Input
//             value={searchValue}
//             noBorders
//             placeholder="Find or add new link"
//             onChange={handleSearch}
//             suffix={
//               <Icon
//                 name={"search"}
//                 color={"text-2"}
//                 size={12}
//                 hidden={readonly || disabled}
//               />
//             }
//           />
//         </div>
//         {selectOptions.filter((option) =>
//           searchValue ? option.value.includes(searchValue) : true
//         )?.length ? (
//           selectOptions
//             .filter((option) =>
//               searchValue ? option.value.includes(searchValue) : true
//             )
//             .map((option) => {
//               return (
//                 <div
//                   className="lf-form-select-search-box lf-form-select-option"
//                   onClick={() => {
//                     setValue(option);
//                   }}
//                 >
//                   <span>{option.value}</span>
//                 </div>
//               );
//             })
//         ) : (
//           <div
//             className="lf-form-select-search-box"
//             onClick={() => addNewOption(searchValue, selectOptions)}
//           >
//             <Icon name={"add"} size={12} hidden={readonly || disabled} />
//             <span>Add Source URL</span>
//           </div>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div
//       className={classNames("lf-form-select", {
//         "lf-form-select--readonly": readonly || disabled,
//       })}
//     >
//       <Select
//         value={value}
//         options={selectOptions}
//         disabled={disabled}
//         onSelect={onHandleSelect}
//         bordered={false}
//         className={multiline && "lf-select-multiline"}
//         dropdownRender={() => dropdownRender(addNewOption, handleSearch, value)}
//         destroyOnClose
//         suffixIcon={
//           <Icon
//             name={"arrow-down"}
//             color={"text-2"}
//             size={12}
//             hidden={readonly || disabled}
//           />
//         }
//         {...(readonly ? { onSelect: undefined } : {})}
//         {...props}
//       />
//       {readonly && <div className={"lf-form-select__readonly"} />}
//     </div>
//   );
// };

export const FormFieldName = ({ text = "", minWidth = 160 }) => {
  return (
    <span className="lf-form-name" style={{ minWidth }}>
      {text}
    </span>
  );
};
