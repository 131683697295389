import { colors } from "../../icons/icons";

const FitFactCard = ({ data }) => {
  console.log("FitFactCard", data);
  const color = colors.find((c) => c.backgroundColor === data?.color);
  return (
    <div
      className="fitfact-card-box"
      style={{
        backgroundColor: color?.backgroundColor,
        borderColor: color?.backgroundColor,
      }}
    >
      <p className="fitfact-card-category-text" style={{ color: color?.color }}>
        FitFact. {data?.Category?.name || ""}
      </p>
      <p className="fitfact-card-title-text" style={{ color: color?.color }}>
        {data.text || ""}
      </p>
      <p className="fitfact-card-source-text" style={{ color: color?.color }}>
        {data.FactCitation?.source || ""}
      </p>
      {data.FactUrl?.source && (
        <div
          className="fitfact-card-link-box"
          role="presentation"
          onClick={() => window.open(data.FactUrl?.source, "_blank")}
        >
          <p className="fitfact-card-link-text" style={{ color: color?.color }}>
            View website
          </p>
        </div>
      )}
    </div>
  );
};

export default FitFactCard;
