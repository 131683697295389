import { useEffect, useMemo, useState } from "react";
import { DrawerHeader } from "../../../../components/drawer/drawer-header";
import { Drawer } from "../../../../components/drawer/drawer";
import { Form } from "antd";
import {
  FormHeaderTextarea,
  FormImage,
  FormSelect,
} from "../../../../components/form/form";
import { Editor } from "../../../../components/editor/editor";
import TagsSelect from "../../../../components/tags-select/tags-select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/user";
import Markdown from "react-markdown";

export const ArticleModal = ({
  form,
  data,
  onClose,
  onSave,
  tags,
  categories,
  articleId,
  updateHasImage,
  updateIsUpdatedImage,
  isLoading,
  authors = [],
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState();
  const [articleImage, setArticleImage] = useState();

  const currentUser = useSelector(selectUser);

  const closeHandler = () => {
    setEditMode(false);
    onClose();
  };

  const editHandler = () => {
    setEditMode(true);
  };

  const saveHandler = async () => {
    await onSave()?.finally?.(() => {
      setEditMode(false);
    });
  };

  const cancelHandler = () => {
    form.resetFields();
    setEditMode(false);
    if (data.status === "draft") {
      onClose();
    }
  };

  const onHandleContentUpdate = (value) => {
    setEditorContent(value);
  };

  const onHandleUpdateImage = (image) => {
    setArticleImage(image);
    form.setFieldValue(
      "image",
      image
    )
  };

  useEffect(() => {
    if (data?.status === "draft") {
      setEditMode(true);
    }

    if (data?.content) {
      setEditorContent(data.text);
    }

    if (data) {
      setArticleImage(data.image);
    }
  }, [data]);

  const updateArticleTags = (values) => {
    form.setFieldValue("tags", values);
  };

  const updateCategory = (category) => {
    form.setFieldValue("categoryId", category);
  };

  const updateImageStatus = (value) => {
    updateHasImage(value);
  };

  const updateAuthor = (author) => {
    form.setFieldValue(
      "userId",
      authors.find((a) => a.fullName === author)?.id
    );
  };

  const drawerContent = useMemo(() => {
    return data ? (
      <Form form={form}>
        <DrawerHeader
          title={
            <Form.Item name={"title"} initialValue={data.title}>
              <FormHeaderTextarea readonly={!editMode} placeholder={"Title"} />
            </Form.Item>
          }
          onEdit={editHandler}
          onSave={saveHandler}
          onCancel={cancelHandler}
          onClose={closeHandler}
          isEditing={editMode}
          isLoading={isLoading}
        />

        <Form.Item name={"categoryId"} initialValue={data.categories}>
          <span className="article-modal-tags-label">Category: </span>
          <FormSelect
            options={categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            }))}
            defaultValue={categories
              .map((cat) => ({ value: cat.id, key: cat.id, label: cat.name }))
              .find((c) => c.value === data.categoryId)}
            onSelect={updateCategory}
            placeholder={editMode && "Select category..."}
            style={{ minWidth: 160 }}
            bordered={false}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"tags"} initialValue={data.tags}>
          <span className="article-modal-tags-label">Tags: </span>
          <TagsSelect
            options={tags}
            defaultValue={data.tags}
            onChange={updateArticleTags}
            onCreate={updateArticleTags}
            readonly={!editMode}
            placeholder="+ Add Tag"
            isEditing={true}
          />
        </Form.Item>
        <Form.Item
          name={"userId"}
          initialValue={authors.find((a) => a.id === data.userId)?.id}
        >
          <span className="article-modal-tags-label">Author: </span>
          <FormSelect
            placeholder={editMode && "Select author"}
            options={authors?.map((author) => ({
              id: author.id,
              value: author.fullName,
            }))}
            defaultValue={() => {
              const usr =
                authors.find((a) => a.id === data.userId) || currentUser;
              return usr ? { id: usr?.id, value: usr?.fullName } : null;
            }}
            onSelect={updateAuthor}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"image"} initialValue={articleImage}>
          <FormImage
            readonly={!editMode}
            onChange={onHandleUpdateImage}
            articleId={articleId}
            updateImageStatus={updateImageStatus}
            updateIsUpdatedImage={updateIsUpdatedImage}
          />
        </Form.Item>
        <Form.Item name={"text"} initialValue={editorContent}>
          {editMode ? (
            <Editor
              content={data.text}
              readonly={!editMode}
              onChange={onHandleContentUpdate}
            />
          ) : (
            <Markdown className="markdown-editor">{data.text}</Markdown>
          )}
        </Form.Item>
      </Form>
    ) : null;
  }, [data, articleImage, editMode, editorContent]);

  return (
    <Drawer open={!!data} onClose={closeHandler} destoyOnClose={true}>
      {drawerContent}
    </Drawer>
  );
};
