import { Body } from "../../../components/main-body/body";
import { ArticleModal } from "./component/article-modal";
import "./article-management.css";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import { useAdminResource } from "../components/hooks/useAdminResource";
import ApiArticles from "../../../api/articles";
import AdminResourceTable from "../components/layouts/general/admin-resource-table";

export const ArticleManagement = () => {
  const {
    form,
    list,
    active,
    update,
    authors,
    open,
    close,
    create,
    search,
    handleUpdateSearchValue,
    remove,
    tags,
    categories,
    updateFilterValue,
    updateHasImage,
    updateIsUpdatedImage,
    isUpdatedImage,
    isLoading,
  } = useAdminResource(ApiArticles);

  return (
    <Body
      header={{
        title: "Articles",
        create: { label: "New article", onClick: create },
        search: { value: search, onHandleChange: handleUpdateSearchValue },
      }}
      filters={
        <DropdownFilters
          filtersBy={[
            { name: "Tags", id: "tag", children: tags },
            { name: "Category", id: "categoryIds", children: categories },
            { name: "Author", id: "userIds", children: authors },
          ]}
          updateFilterValue={updateFilterValue}
        />
      }
    >
      <ArticleModal
        articleId={active?.id}
        form={form}
        data={active}
        onClose={close}
        onSave={update}
        tags={tags}
        categories={categories}
        updateHasImage={updateHasImage}
        updateIsUpdatedImage={updateIsUpdatedImage}
        isLoading={isLoading}
        authors={authors}
      />
      <AdminResourceTable
        data={list}
        onHandleRowClick={open}
        remove={remove}
        tableComlumnKeys={["cover", "title", "updatedAt", "action"]}
        isLoading={isLoading}
        shouldDropCache={isUpdatedImage}
      />
    </Body>
  );
};
